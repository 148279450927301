import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { ApolloError } from 'apollo-client';

import { useDeps } from 'src/hooks/useDeps';
import { CategoryItem } from 'src/components/CategoryList/CategoryList';
import { homeQuery } from 'src/queries/__generated__/homeQuery';
import homepageQuery from 'src/queries/homeQuery.graphql';

import { carouselFromHomeQuery, categoriesFromHomeQuery, promotionsFromHomeQuery } from './utils/home-data';
import { CarouselItem } from './HomeCarousel';
import { PromotionItem } from './Promotions';

type HomeContextType = {
    carousel: CarouselItem[];
    categories: CategoryItem[];
    error: ApolloError | undefined;
    loading: boolean | undefined;
    promotions: PromotionItem[];
};

export const HomeContext = React.createContext<HomeContextType>({
    carousel: [],
    categories: [],
    error: undefined,
    loading: false,
    promotions: [],
});

export const HomeProvider = React.memo((props) => {
    const { error, data, loading } = useQuery<homeQuery>(homepageQuery, {
        ssr: useDeps().env.SSR_GQL,
    });

    const api: HomeContextType = {
        carousel: carouselFromHomeQuery(data?.homepageCarousel),
        categories: categoriesFromHomeQuery(data?.jhMenu),
        error: error,
        loading: loading,
        promotions: promotionsFromHomeQuery(data?.homepageCta),
    };

    return <HomeContext.Provider value={api}>{props.children}</HomeContext.Provider>;
});
