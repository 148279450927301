import React, { useContext, useRef } from 'react';
import Slider, { Settings } from 'react-slick';
import { Link } from 'react-router-dom';

import { LazyPicture } from 'src/hooks/useLazyLoad';
import { HomeContext } from 'src/components/Home/HomeProvider';
import { ButtonLink } from 'src/components/Button/ButtonLink';

import classes from './HomeCarousel.scss';

export interface CarouselItem {
    button_text?: string;
    image: string;
    imageLap: string;
    imageMob: string;
    text?: string | null;
    title?: string;
    url?: string;
}

type HomeCarouselProps = {
    items: CarouselItem[];
};

export const HomeCarousel: React.FC<HomeCarouselProps> = ({ items }) => {
    const sliderRef = useRef<Slider>(null);

    const settings: Settings = {
        accessibility: true,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 4000,
        className: classes.homeSlider,
        dots: true,
        draggable: false,
        infinite: true,
        pauseOnHover: true,
        slidesToScroll: 1,
        slidesToShow: 1,
        speed: 500,
    };

    return (
        <div className={classes.sliderWrapper}>
            <Slider ref={sliderRef} {...settings}>
                {items.map((item, index) => {
                    return (
                        <div key={item.title} className={classes.slide}>
                            {index === 0 && <link rel="preload" href={item.image} as="image" />}
                            {item.url && <Link to={item.url} className={classes.contentLink} />}

                            <LazyPicture data-alt={item.title} className={classes.imageContainer}>
                                <source media="(min-width: 1200px)" srcSet={item.image} />
                                <source media="(min-width: 750px)" srcSet={item.imageLap} />
                                <source media="(max-width: 750px)" srcSet={item.imageMob} />
                            </LazyPicture>

                            {item.title && (
                                <div className={classes.content}>
                                    <div className={classes.contentInfo}>
                                        {item.text && (
                                            <p className={classes.contentDesc}>{decodeHTMLEntities(item.text)}</p>
                                        )}
                                        {item.title && (
                                            <h2 className={classes.contentTitle}>{decodeHTMLEntities(item.title)}</h2>
                                        )}
                                    </div>
                                    {item.button_text && item.url && (
                                        <ButtonLink to={item.url} className={classes.contentButton}>
                                            {item.button_text}
                                        </ButtonLink>
                                    )}
                                </div>
                            )}
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export const HomeCarouselWrapper = React.memo(() => {
    const { carousel } = useContext(HomeContext);

    if (!carousel.length) {
        return null;
    }

    return <HomeCarousel items={carousel} />;
});

function decodeHTMLEntities(text) {
    const entities = [
        ['amp', '&'],
        ['#039', "'"],
    ];

    for (let i = 0, max = entities.length; i < max; ++i)
        text = text.replace(new RegExp('&' + entities[i][0] + ';', 'g'), entities[i][1]);

    return text;
}
