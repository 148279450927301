import { homeQuery } from 'src/queries/__generated__/homeQuery';
import { CategoryItem } from 'src/components/CategoryList/CategoryList';
import { imagePath } from 'src/util/formatUrl';
import { CarouselItem } from 'src/components/Home/HomeCarousel';
import { stripDomain } from 'src/util/sanitizeLink';

import { PromotionItem } from '../Promotions';

const EMPTY_ARR = [];
export function categoriesFromHomeQuery<T extends homeQuery['jhMenu']>(items?: T): CategoryItem[] {
    if (!items) {
        return EMPTY_ARR;
    }

    return items.map((item) => {
        if (!item) {
            throw new Error('Cannot process category');
        }

        return {
            title: item.title || 'unknown',
            image: imagePath(item.image_url),
            link: item.url || '/',
            shortDescription: null,
        };
    });
}

export function carouselFromHomeQuery<T extends homeQuery['homepageCarousel']>(items?: T): CarouselItem[] {
    if (!items) {
        return EMPTY_ARR;
    }

    return items.map((item) => {
        if (!item) {
            throw new Error('Cannot process carousel item');
        }

        return {
            button_text: item.button_text ?? undefined,
            image: imagePath(item.image),
            imageLap: imagePath(item.image_lap),
            imageMob: imagePath(item.image_mobile),
            text: item.text ?? undefined,
            title: item.title ?? undefined,
            url: stripDomain(item.url),
        };
    });
}

export function promotionsFromHomeQuery<T extends homeQuery['homepageCta']>(items?: T): PromotionItem[] {
    if (!items) {
        return EMPTY_ARR;
    }

    return items.map((item) => {
        if (!item) {
            throw new Error('Cannot process promotion');
        }
        return {
            image: imagePath(item.image),
            title: item.title || 'unknown',
            url: item.url || '/',
        };
    });
}
