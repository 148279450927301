import { Link } from 'react-router-dom';
import React from 'react';

import { LazyImg } from 'src/hooks/useLazyLoad';
import { CategoryItem } from 'src/components/CategoryList/CategoryList';

import classes from './CategoryListItems.scss';

interface CategoryListItemsProps {
    categories: CategoryItem[];
    isHomePage?: boolean;
}

export function CategoryListItems(props: CategoryListItemsProps) {
    return (
        <ul className={classes.items} data-is-home={props.isHomePage}>
            {props.categories.map((category) => {
                return (
                    <li key={category.title} className={classes.item} data-test-id="categoryListItem">
                        <Link className={classes.imageContainer} to={category.link}>
                            <LazyImg className={classes.image} src={category.image} alt={category.title} />
                        </Link>
                        <Link className={classes.itemTitle} to={category.link}>
                            {category.title}
                        </Link>
                        <p className={classes.shortDesc}>{category.shortDescription}</p>
                    </li>
                );
            })}
        </ul>
    );
}
